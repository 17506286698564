@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}
.btn-primary {
  --bs-btn-color: #1c64f2;
}
.btn-secondary {
  --bs-btn-color: #6c757d;
}
